<template>
    <el-card class="evaluationProject mainContent">
        <div class="search-box">
            <el-form ref="searchForm" :model="searchParams" inline>
                <el-form-item>
                    <el-input v-model="searchParams.keyWord" clearable class="w300" placeholder="评估对象姓名/手机号/评估结果"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">搜索</el-button>
                    <!-- <el-button type="text" @click="doDown()">下载报告</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            style="width: 100%; margin-top: 10px"
            :data="tableData"
            border
            :header-cell-style="headerCellStyle"
            @expand-change="expandChange"
        >
            <template slot="empty">
                <IsEmpty />
            </template>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-table :data="props.row.children" border style="width: 100%">
                        <el-table-column prop="projectName" label="评估项目" width="210" align="center"> </el-table-column>
                        <el-table-column prop="evaluatorName" label="评估员" align="center"> </el-table-column>
                        <el-table-column prop="name" label="评估模板" align="center"> </el-table-column>
                        <el-table-column prop="evaluationTime" label="评估时间" align="center" width="180"> </el-table-column>
                        <el-table-column prop="status" align="center" label="评估状态">
                            <template slot-scope="scope">
                                {{ ['进行中', '已完成'][scope.row.status] }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" @click="toDetail(scope.row)" v-if="scope.row.status === 1">查看</el-button>
                                <el-button type="text" @click="doDown(scope.row)" v-if="scope.row.status === 1">下载报告</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="评估对象" align="center" />
            <el-table-column prop="phone" label="手机" show-overflow-tooltip align="center" />
            <el-table-column prop="birthday" label="出生日期" show-overflow-tooltip align="center" />
            <el-table-column prop="evaluationsNumber" label="评估次数" show-overflow-tooltip align="center" />
            <el-table-column prop="evaluationResults" label="评估结果" show-overflow-tooltip align="center" />
        </el-table>
        <el-pagination
            background
            @size-change="getList"
            @current-change="getList"
            :current-page.sync="queryParams.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size.sync="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
        >
        </el-pagination>
    </el-card>
</template>

<script>
import { assessorObjectPage, projectList, reportExport } from '@/api/evaluationProject'
import { downloadStream } from '@/utils/index'
import config from '@/api/config'
import axios from 'axios'
export default {
    // 评估项目
    name: 'evaluationProject',
    data() {
        return {
            tableData: [],
            children: [],
            searchParams: {
                keyWord: ''
            },
            queryParams: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            loading: false
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.loading = true
            assessorObjectPage({ ...this.searchParams, ...this.queryParams })
                .then(res => {
                    this.tableData = res.data.records
                    this.total = res.data.total
                    this.loading = false
                    this.tableData.forEach(item => {
                        this.$set(item, 'children', [])
                    })
                })
                .catch(() => {
                    this.loading = false
                })
        },
        /*查看详情*/
        toDetail(row) {
            this.$router.push({
                path: '/evaluationProject/computerTemplate',
                query: {
                    projectId: row.id,
                    objectId: row.objectId
                }
            })
        },
        otherDown() {
            const userInfo = JSON.parse(localStorage.getItem('cad_userInfo'))
            axios
                .get(
                    `${config.CIVIL_HOST}/assessEvaluationProject/report/export`,
                    {
                        headers: {
                            Authorization: userInfo.token
                        },
                        params: {
                            projectId: 1,
                            objectId: 1
                        },
                        responseType: 'arraybuffer'
                    },
                    { responseType: 'arraybuffer' }
                )
                .then(res => {
                    let fileName = '评估报告.docx'
                    let fileURL = window.URL.createObjectURL(new Blob([res.data]))
                    let fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    fileLink.setAttribute('download', fileName)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                })
                .catch(e => {
                    console.log(e, '>>>>>>>>>>>>>>>>>>>>>>.')
                })
        },
        // 下载报告
        async doDown(row) {
            const res = await reportExport({ projectId: row.id, objectId: row.objectId })
            // const res = await reportExport({ projectId: 1, objectId: 1 })
            downloadStream(res, `评估报告.docx`)
        },
        // 表格展开
        async expandChange(row) {
            if (!row.children.length) {
                const res = await projectList({ id: row.id })
                row.children = res.data
            }
        },
        // 搜索
        doSearch() {
            this.queryParams.pageNum = 1
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table th,
.el-table tr {
    background-color: #fafafa;
    color: #606162;
}
.table-fixed {
    ::v-deep .el-table__fixed-right {
        height: 100% !important;
    }
}

@media (max-width: 1280px) {
    ::v-deep .el-button--mini {
        display: block;
        margin: 3px auto;
    }
}

::v-deep .el-table .cell.el-tooltip {
    div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
    }
}
::v-deep .el-table__expanded-cell {
    padding: 20px 50px;
}
</style>
